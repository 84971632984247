/*@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

html, body {
    overflow-x: hidden;
    /*font-family: 'Varela Round', sans-serif;*/
    font-family: 'Nunito', sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

#root {
    font-family: 'Nunito', sans-serif;
    min-height: 100%;
    width: 100%;
}